<template>
<div>

  <div class="col-lg-9 col-md-9 col-sm-12">
  <div class="blog blog-style-default">
<article v-for="news in newsitems" :id="`post-${news.id}`" :class="`rounded-corners default-background post-${news.id} post type-post status-publish format-standard has-post-thumbnail hentry category-announcements category-events tag-115 tag-128 tag-114 tag-106`">
  <div class="item-post-container">
<div class="item-post clearfix">


  <div class="post-image">

                    <router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" class="default"><img width="540" height="390" :src="news.imagesrcmedium" class="img-responsive wp-post-image" alt="" /></router-link>


  </div>
  <div class="post-text">
    <header class="entry-header">

      <div class="entry-title"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" rel="bookmark"><div v-html="`${news.title.rendered}`"> </div> </router-link></div>
      <div class="entry-meta">
                        <taglinks :categories="news.categoriesarray"/>
                    </div>
      <!-- .entry-meta -->
    </header>
    <!-- .entry-header -->

                <div class="entry-content">
                            <div v-html="news.excerpt.rendered"></div>
                </div>

    <div class="clearfix entry-info">
                    <span class="entry-date">{{news.postdateformated}}</span>																			<span class="read-more-link"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}"> Далее</router-link></span>
                </div>
  </div>
</div>
</div>
</article><!-- #post-12118 -->


</div>
<Paginator :curpage="showpage"  :totalpages="pagestotal" />
</div>

</div>
</template>

<script>
import axios from "axios"
import "datejs"
import {getwpposts} from "@/lib/utils.js"
import taglinks from "@/components/taglinks.vue"
import Paginator from "@/components/paginator.vue"
export default {
components : {taglinks, Paginator},
name : "newsblockUniversal",
props : [
'category','count','page'
    ],

data() {

let showpage=1

console.log('this.page = '+this.page)

if(this.page)
{
  showpage = this.page
}

return {
newsitems : [],
onpagecount : 6,
showpage,
pagestotal  : 1,
paginatorshow : false
 }

},
mounted() {

this.getnews()

},
methods : {

async getnews()  {

console.log("Getting news, page="+this.showpage)
console.log(`Category ${this.category}`)

this.newsitems = await getwpposts(this.category,this.onpagecount,this.showpage)

if(this.newsitems)
  if(this.newsitems[0])
    if(this.newsitems[0].totalpages)
      this.pagestotal = this.newsitems[0].totalpages


    }




},
watch : {

$route(){

console.log(" newsblock-universal.vue : route changed")

if(this.$route.params)
   if(this.$route.params.page)
     {
       this.showpage = this.$route.params.page
        this.getnews()
     }

}

}

}

</script>
